<style>
.settings-collapse {
	margin-bottom: 1rem;
}

.hard-border {
	border-radius: 0 !important;
}

@media (max-width: 1024px) {
	.sm-spacer {
		margin-top: .5rem;
	}
}

.save-btn.btn-success {
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 #34c38f;
	}

	100% {
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}
}

@keyframes pulsePrimary {
	0% {
		box-shadow: 0 0 0 0 #4285F4;
	}

	100% {
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}
}

@keyframes pulseDanger {
	0% {
		box-shadow: 0 0 0 0 #f46a6a;
	}

	100% {
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}
}
</style>
<script>
import {VclCode} from 'vue-content-loading';
import {HalfCircleSpinner} from 'epic-spinners';
import Multiselect from "vue-multiselect";
import {countries, timezones} from './countryData';

import {get_acsrf_token} from "@/methods";
import {mapGetters} from "vuex";
import {Game, OmegaAction, StreamEvent, TriggerAttributes, TriggerCondition} from "@/enums";

export default {
	props: ['server_id', 'options'],
	components: {
		VclCode,
		HalfCircleSpinner,
		Multiselect
	},
	computed: {
		...mapGetters([
			'getDTLocale'
		]),
	},
	methods: {
		handleError: function (error) {
			console.log(`[ERROR] ${error}`);
			this.error = true;
		},
		getConfiguration: function () {
			fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/configuration`, {credentials: 'include'})
					.then(response => {
						if (response.ok) {
							return response.json();
						} else {
							throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
						}
					})
					.then(data => {
						this.configuration = data.configuration;
						this.features = data.features;
						this.gameserver = data.gameserver;

						// this.selectedCountries = [];
						this.configuration.geoblocking.country_codes.forEach((cc) => {
							let countryName = null;

							countries.forEach((country) => {
								if (country.code === cc) {
									countryName = country.code;
									return;
								}
							})
							if (!this.selectedCountries.includes(countryName)) this.selectedCountries.push({
								name: countryName,
								code: cc
							});
						});

						this.special.steam.kick_vac = this.configuration.steam.kick_vac === 1 ? true : false;

						this.ready = true;

						this.$nextTick(() => {
							this.configurationChanged = false;
						});
					})
					.catch(error => {
						this.handleError(error);
					});
		},
		/* Support features per game */
		// TODO: Individual components per game for simpler overview
		supportsKillfeed() {
			// Supports modding or has native method of kill transmission
			let games = [Game.DAYZ];
			return games.includes(this.options.game);
		},
		supportsWhitelist() {
			// Supports either native whitelist or emulated whitelist by kicking
			let games = [Game.DAYZ, Game.ARMA2, Game.ARMA3];
			return games.includes(this.options.game);
		},
		supportsReservedSlots() {
			// Either native or emulated reserved slot support by kicking
			let games = [];
			return games.includes(this.options.game);
		},
		supportsIPFeatures() {
			// Games that allow the retrieval of plain text player IP
			let games = [Game.DAYZ, Game.ARMA2, Game.ARMA3];
			return games.includes(this.options.game);
		},
		supportsSteam() {
			// Steam auth based games
			let games = [Game.DAYZ, Game.ARMA2, Game.ARMA3];
			return games.includes(this.options.game);
		},
		/* ************************** */
		async saveConfiguration() {
			this.inProgress = true;
			await new Promise(resolve => setTimeout(resolve, 500));

			try {
				let acsrf_token = await get_acsrf_token();
				let payload = {
					acsrf_token: acsrf_token,
					configuration: this.configuration
				};
				let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/configuration`, {
					method: 'PATCH',
					body: JSON.stringify(payload),
					credentials: 'include'
				});
				if (response.ok) {
					this.$swal({
						icon: 'success',
						title: this.$t('server.view.configuration.save.success.title'),
						text: this.$t('server.view.configuration.save.success.text'),
					});
					this.configurationChanged = null;
					await this.getConfiguration();
				} else {
					if (response.status === 429) {
						this.$swal({
							icon: 'warning',
							text: this.$t('error.server.ratelimit.message'),
							title: this.$t('error.server.ratelimit.title')
						});
					} else
						throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
				}
			} catch (error) {
				console.log(`[ERROR] ${error}`);
				this.$swal({
					icon: 'error',
					text: this.$t('error.server.generic.message')
				});
			}
			this.inProgress = false;
		},
		scrollHandler() {
			if(!this.$refs.floatingContainer) return;

			if(window.pageYOffset > 70) {
				if(!this.$refs.floatingContainer.classList.contains('c-floating-side')) {
					this.$refs.floatingContainer.classList.add('c-floating-side');
				}
			} else {
				this.$refs.floatingContainer.classList.remove('c-floating-side');
			}
		}
	},
	mounted() {
		this.getConfiguration();

		window.addEventListener('scroll', this.scrollHandler);
	},
	destroyed() {
		window.removeEventListener('scroll', this.scrollHandler);
	},
	watch: {
		configuration: {
			deep: true,
			handler(newValue, oldValue) {
				if (!this.ready) return;
				if (this.configurationChanged === null) {
					this.configurationChanged = false;
				} else {
					this.configurationChanged = true;
				}
			}
		},
		'configuration.general.host'() {
			if (!this.configurationChanged) return;
			this.connectionDetailsChanged = true;
		},
		'configuration.general.port'() {
			if (!this.configurationChanged) return;
			this.connectionDetailsChanged = true;
		},
		'configuration.general.password'() {
			if (!this.configurationChanged) return;
			this.connectionDetailsChanged = true;
		},
		'configuration.vpn_protection.force_residential'() {
			this.configuration.vpn_protection.__ob__.dep.notify();
			if(this.configuration && !this.configuration.vpn_protection && this.ready && this.configurationChanged === null) {
				if (this.configuration.vpn_protection.force_residential === false) {
					this.configuration.vpn_protection.block_business = false;
				}
				this.configuration.vpn_protection.block_vpn = this.configuration.vpn_protection.force_residential ? true : false;
				this.configuration.vpn_protection.block_mobile = this.configuration.vpn_protection.force_residential ? true : false;
				this.configuration.vpn_protection.block_hosting = this.configuration.vpn_protection.force_residential ? true : false;
				this.configuration.vpn_protection.block_business = this.configuration.vpn_protection.force_residential ? true : false;
			}
		},
		selectedCountries: {
			deep: true,
			handler() {
				if (!this.ready) return;
				this.configuration.geoblocking.country_codes = [];
				this.selectedCountries.forEach((country) => {
					this.configuration.geoblocking.country_codes.push(country.code);
				})
			}
		},
		'special.steam.kick_vac'() {
			if (!this.ready) return;
			this.configuration.steam.kick_vac = this.special.steam.kick_vac === true ? 1 : 0;
		}
	},
	data() {
		return {
			window: window,
			Game: Game,
			OmegaAction: OmegaAction,
			TriggerCondition: TriggerCondition,
			TriggerAttributes: TriggerAttributes,
			StreamEvent: StreamEvent,
			ready: false,
			error: false,
			inProgress: false,
			configurationChanged: null,
			connectionDetailsChanged: false,
			connectionDetailsError: false,
			features: null,
			configuration: {
				reserved_slot: {
					slots: 0
				}
			},
			gameserver: {},
			// Special configs
			availableCountries: countries,
			selectedCountries: [],
			special: {
				steam: {
					kick_vac: false
				}
			},
			availableTimezones: timezones,
		}
	}
};
</script>

<template>
	<div>
		<template v-if="ready">
			<div class="row">
				<div class="col-sm-12 d-lg-none">
					<button class="btn btn-block hard-border save-btn"
					        v-on:click="saveConfiguration()"
					        :disabled="inProgress"
					        :class="{
                    'disabled': inProgress,
                    'btn-success': configurationChanged === true,
                    'btn-primary': configurationChanged === false
                  }"
					>
						<half-circle-spinner
								v-if="inProgress"
								:animation-duration="1200"
								:size="16"
								class="align-middle d-inline-block"
						/>
						<template v-if="!inProgress">
							{{ $t('server.view.configuration.save.button') }}
						</template>
					</button>
					<div class="text-center small text-muted" v-if="configurationChanged === true">
						{{ $t('server.view.configuration.save.info') }}
					</div>
				</div>
				<div class="col-lg-9 col-sm-12 sm-spacer">
					<div>
						<div role="tablist">
							<!-- Ping limiter -->
							<b-card no-body class="settings-collapse hard-border">
								<a v-b-toggle.pingLimiter class="text-dark" href="javascript: void(0);">
									<b-card-header header-tag="header" role="tab">
										<h3 class="m-0 d-inline-flex">
											{{ $t('server.view.configuration.pinglimiter.title') }}
										</h3>
										<div class="d-inline-flex float-right">
											<div class="btn btn-sm btn-dark">
												{{ $t('server.view.configuration.expand') }}
											</div>
										</div>
									</b-card-header>
								</a>
								<b-collapse id="pingLimiter" accordion="configuration" role="tabpanel">
									<b-card-body>
										<b-card-text>
											<!-- Module toggle -->
											<div class="row">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.pinglimiter.active.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.pinglimiter.active.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.ping_limiter.status"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														/>
													</div>
												</div>
											</div>
											<!---------------------------------------->

											<!-- Protect whitelisted -->
											<div class="row mt-2" v-if="supportsWhitelist()">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.pinglimiter.protectwl.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.pinglimiter.protectwl.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.ping_limiter.protect_whitelisted"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														/>
													</div>
												</div>
											</div>
											<!---------------------------------------->

											<!-- Send warnings -->
											<div class="row mt-2">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.pinglimiter.warn.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.pinglimiter.warn.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.ping_limiter.warn"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														/>
													</div>
												</div>
											</div>
											<!---------------------------------------->

											<!-- Max ping -->
											<div class="row mt-2">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.pinglimiter.max.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.pinglimiter.max.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<b-input v-model="configuration.ping_limiter.max" size="lg" type="number"
													         class="form-control"/>
												</div>
											</div>
											<!---------------------------------------->
										</b-card-text>
									</b-card-body>
								</b-collapse>
							</b-card>

							<!-- Reserved Slots -->
							<b-card no-body class="settings-collapse hard-border" v-if="supportsReservedSlots()">
								<a v-b-toggle.reservedSlots class="text-dark" href="javascript: void(0);">
									<b-card-header header-tag="header" role="tab">
										<h3 class="m-0 d-inline-flex">
											{{ $t('server.view.configuration.reservedslots.title') }}
										</h3>
										<div class="d-inline-flex float-right">
											<div class="btn btn-sm btn-dark">
												{{ $t('server.view.configuration.expand') }}
											</div>
										</div>
									</b-card-header>
								</a>
								<b-collapse id="reservedSlots" accordion="configuration" role="tabpanel">
									<b-card-body>
										<b-card-text>
											<!-- Module toggle -->
											<div class="row">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.reservedslots.active.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.reservedslots.active.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.reserved_slot.status"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														/>
													</div>
												</div>
											</div>
											<!---------------------------------------->

											<!-- Slot count -->
											<div class="row mt-2">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.reservedslots.slots.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.reservedslots.slots.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<b-input v-model="configuration.reserved_slot.slots" size="lg" type="number"
													         class="form-control"/>
												</div>
											</div>
											<!---------------------------------------->
										</b-card-text>
									</b-card-body>
								</b-collapse>
							</b-card>

							<!-- Whitelist -->
							<b-card no-body class="settings-collapse hard-border" v-if="supportsWhitelist()">
								<a v-b-toggle.whitelist class="text-dark" href="javascript: void(0);">
									<b-card-header header-tag="header" role="tab">
										<h3 class="m-0 d-inline-flex">
											{{ $t('server.view.configuration.whitelist.title') }}
										</h3>
										<div class="d-inline-flex float-right">
											<div class="btn btn-sm btn-dark">
												{{ $t('server.view.configuration.expand') }}
											</div>
										</div>
									</b-card-header>
								</a>
								<b-collapse id="whitelist" accordion="configuration" role="tabpanel">
									<b-card-body>
										<b-card-text>
											<!-- Mopdule enabled -->
											<div class="row">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.whitelist.active.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.whitelist.active.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.whitelist.status"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														/>
													</div>
												</div>
											</div>
										</b-card-text>
									</b-card-body>
								</b-collapse>
							</b-card>
							<!---------------------------------------->

							<!-- VPN protection -->
							<b-card no-body class="settings-collapse hard-border" v-if="supportsIPFeatures()">
								<a v-b-toggle.vpnProtection class="text-dark" href="javascript: void(0);">
									<b-card-header header-tag="header" role="tab">
										<h3 class="m-0 d-inline-flex">
											{{ $t('server.view.configuration.vpn.title') }}
										</h3>
										<div class="d-inline-flex float-right">
											<div class="btn btn-sm btn-dark">
												{{ $t('server.view.configuration.expand') }}
											</div>
										</div>
									</b-card-header>
								</a>
								<b-collapse id="vpnProtection" accordion="configuration" role="tabpanel">
									<b-card-body>
										<b-card-text>
											<!-- Mopdule enabled -->
											<div class="row">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.vpn.active.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.vpn.active.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.vpn_protection.status"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														/>
													</div>
												</div>
											</div>
											<!---------------------------------------->

											<!-- Protect whitelist -->
											<div class="row mt-2">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.vpn.protectwl.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.vpn.protectwl.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.vpn_protection.protect_whitelisted"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														/>
													</div>
												</div>
											</div>
											<!---------------------------------------->

											<hr>

											<!-- Force Residential -->
											<div class="row mt-2">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.vpn.residential.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.vpn.residential.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.vpn_protection.force_residential"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														/>
													</div>
												</div>
											</div>
											<!---------------------------------------->

											<!-- Block VPN -->
											<div class="row mt-2">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.vpn.vpn.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.vpn.vpn.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.vpn_protection.block_vpn"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														               :class="{'disabled': configuration.vpn_protection.force_residential}"
														/>
													</div>
												</div>
											</div>
											<!---------------------------------------->


											<!-- Block Business -->
											<!-- TODO: Disabled because of common missclassification
											<div class="row mt-2">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.vpn.business.title') }}
														</h4>
														<span>
															{{ $t('server.view.configuration.vpn.business.description') }}
														</span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.vpn_protection.block_business"
																					 :sync="true"
																					 :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
																					 :height="40"
																					 :width="80"
																					 :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
																					 style="font-size: 13px !important;"
																					 class="m-0 mt-1 mb-1"
																					 :class="{'disabled': configuration.vpn_protection.force_residential}"
														/>
													</div>
												</div>
											</div>
											-->
											<!---------------------------------------->

											<!-- Block Hosting -->
											<div class="row mt-2">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.vpn.hosting.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.vpn.hosting.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.vpn_protection.block_hosting"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														               :class="{'disabled': configuration.vpn_protection.force_residential}"
														/>
													</div>
												</div>
											</div>
											<!---------------------------------------->

											<!-- Block Wireless -->
											<div class="row mt-2">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.vpn.wireless.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.vpn.wireless.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.vpn_protection.block_mobile"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														               :class="{'disabled': configuration.vpn_protection.force_residential}"
														/>
													</div>
												</div>
											</div>
											<!---------------------------------------->

											<div class="row mt-4">
												<div class="col">
													<div class="text-center">
														<i class="text-info">{{ $t('server.view.configuration.vpn.disclaimer.message') }}</i>
													</div>
												</div>
											</div>

										</b-card-text>
									</b-card-body>
								</b-collapse>
							</b-card>
							<!---------------------------------------->

							<!-- GeoBlocking -->
							<b-card no-body class="settings-collapse hard-border" v-if="supportsIPFeatures()">
								<a v-b-toggle.geoBlocking class="text-dark" href="javascript: void(0);">
									<b-card-header header-tag="header" role="tab">
										<h3 class="m-0 d-inline-flex">
											{{ $t('server.view.configuration.geoblocking.title') }}
										</h3>
										<div class="d-inline-flex float-right">
											<div class="btn btn-sm btn-dark">
												{{ $t('server.view.configuration.expand') }}
											</div>
										</div>
									</b-card-header>
								</a>
								<b-collapse id="geoBlocking" accordion="configuration" role="tabpanel">
									<b-card-body>
										<b-card-text>
											<!-- Mopdule enabled -->
											<div class="row">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.geoblocking.active.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.geoblocking.active.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.geoblocking.status"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														/>
													</div>
												</div>
											</div>
											<!---------------------------------------->

											<!-- Protect whitelist -->
											<div class="row mt-2">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.geoblocking.protectwl.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.geoblocking.protectwl.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.geoblocking.protect_whitelisted"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														/>
													</div>
												</div>
											</div>
											<!---------------------------------------->

											<!-- Country Codes -->
											<div class="row mt-2">
												<div class="col-lg-7 col-sm-12 m-0 p-0">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.geoblocking.countries.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.geoblocking.countries.description') }}
                            </span>
													</div>
												</div>
												<div class="col-lg-5 col-sm-12 m-0 p-0">
													<div style="text-align: center;">
														<multiselect
																v-model="selectedCountries"
																:options="availableCountries"
																:multiple="true"
																:close-on-select="false"
																:clear-on-select="false"
																:preserve-search="true"
																:placeholder="$t('server.view.configuration.geoblocking.countries.placeholder')"
																label="name"
																track-by="code"
																:preselect-first="false"
																:taggable="false"
														>
															<template slot="selection" slot-scope="{ values, search, isOpen }">
																<!--
																<template slot="tag">
																	<span class="custom__tag">
																		 Keep empty
																	</span>
																</template>
																-->
																<span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">
                                  {{ values.length }} {{
																		$t('server.view.configuration.geoblocking.countries.selected')
																	}}
                                </span>
															</template>
														</multiselect>
													</div>
												</div>
											</div>
											<!---------------------------------------->

											<!-- Invert -->
											<div class="row mt-2">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.geoblocking.invert.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.geoblocking.invert.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.geoblocking.invert"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														/>
													</div>
												</div>
											</div>
											<!---------------------------------------->
										</b-card-text>
									</b-card-body>
								</b-collapse>
							</b-card>
							<!---------------------------------------->

							<!-- Steam -->
							<b-card no-body class="settings-collapse hard-border" v-if="supportsSteam()">
								<a v-b-toggle.steam class="text-dark" href="javascript: void(0);">
									<b-card-header header-tag="header" role="tab">
										<h3 class="m-0 d-inline-flex">
											<i class="fab fa-steam mr-1"/>
											{{ $t('server.view.configuration.steam.title') }}
										</h3>
										<div class="d-inline-flex float-right">
											<div class="btn btn-sm btn-dark">
												{{ $t('server.view.configuration.expand') }}
											</div>
										</div>
									</b-card-header>
								</a>
								<b-collapse id="steam" accordion="configuration" role="tabpanel">
									<b-card-body>
										<b-card-text>
											<!-- Mopdule enabled -->
											<div class="row">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.steam.active.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.steam.active.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.steam.status"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														/>
													</div>
												</div>
											</div>
											<!---------------------------------------->

											<!-- Kick private -->
											<div class="row mt-2">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.steam.kickprivate.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.steam.kickprivate.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.steam.kick_private"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														/>
													</div>
												</div>
											</div>
											<!---------------------------------------->

											<!-- Kick private wl protect -->
											<div class="row mt-2">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.steam.kickprivatewl.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.steam.kickprivatewl.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.steam.private_protect_whitelisted"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														/>
													</div>
												</div>
											</div>
											<!---------------------------------------->

											<!-- Kick VAC -->
											<div class="row mt-2">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.steam.kickvac.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.steam.kickvac.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="special.steam.kick_vac"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														/>
													</div>
												</div>
											</div>
											<!---------------------------------------->

											<!-- Kick VAC wl protect -->
											<div class="row mt-2">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.steam.kickvacwl.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.steam.kickvacwl.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.steam.vac_protect_whitelisted"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														/>
													</div>
												</div>
											</div>
											<!---------------------------------------->

											<!-- VAC recovery -->
											<div class="row mt-2">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.steam.vacrecovery.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.steam.vacrecovery.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<b-input v-model="configuration.steam.vac_limition_period" size="lg" type="number"
														         class="form-control"/>
														<div class="small">
															{{ $t('server.view.configuration.steam.vacrecovery.info') }}
														</div>
													</div>
												</div>
											</div>
											<!---------------------------------------->

											<!-- Account age -->
											<div class="row mt-2">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.steam.age.title') }}
														</h4>
														<span class="badge badge-danger text-black font-size-12">
                              {{ $t('server.view.configuration.steam.age.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<b-input v-model="configuration.steam.required_account_age" size="lg" type="number"
														         class="form-control"/>
														<div class="small">

															{{ $t('server.view.configuration.steam.age.info') }}
														</div>
													</div>
												</div>
											</div>
											<!---------------------------------------->

										</b-card-text>
									</b-card-body>
								</b-collapse>
							</b-card>
							<!---------------------------------------->

							<!-- Banning -->
							<b-card no-body class="settings-collapse hard-border">
								<a v-b-toggle.banning class="text-dark" href="javascript: void(0);">
									<b-card-header header-tag="header" role="tab">
										<h3 class="m-0 d-inline-flex">
											{{ $t('server.view.configuration.banning.title') }}
										</h3>
										<div class="d-inline-flex float-right">
											<div class="btn btn-sm btn-dark">
												{{ $t('server.view.configuration.expand') }}
											</div>
										</div>
									</b-card-header>
								</a>
								<b-collapse id="banning" accordion="configuration" role="tabpanel">
									<b-card-body>
										<b-card-text>
											<!-- Module enabled -->
											<div class="row">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.banning.protectwl.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.banning.protectwl.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.banning.protect_whitelisted"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														/>
													</div>
												</div>
											</div>
										</b-card-text>
									</b-card-body>
								</b-collapse>
							</b-card>
							<!---------------------------------------->

							<!-- Timezone -->
							<b-card no-body class="settings-collapse hard-border">
								<a v-b-toggle.timezone class="text-dark" href="javascript: void(0);">
									<b-card-header header-tag="header" role="tab">
										<h3 class="m-0 d-inline-flex">
											{{ $t('server.view.configuration.timezone.title') }}
										</h3>
										<div class="d-inline-flex float-right">
											<div class="btn btn-sm btn-dark">
												{{ $t('server.view.configuration.expand') }}
											</div>
										</div>
									</b-card-header>
								</a>
								<b-collapse id="timezone" accordion="configuration" role="tabpanel">
									<b-card-body>
										<b-card-text>
											<!-- Server Timezone -->
											<div class="row">
												<div class="col-lg-7 col-sm-12 m-0 p-0">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.configuration.timezone.select.title') }}
														</h4>
														<span>
                              {{ $t('server.view.configuration.timezone.select.description') }}
                            </span>
													</div>
												</div>
												<div class="col-lg-5 col-sm-12 m-0 p-0">
													<div style="text-align: center;">
														<multiselect
																v-model="configuration.general.timezone"
																:options="availableTimezones"
																:multiple="false"
																:close-on-select="true"
																:clear-on-select="false"
																:preserve-search="true"
																:placeholder="$t('server.view.configuration.timezone.select.placeholder')"
																:preselect-first="false"
																:taggable="false"
														/>
													</div>
												</div>
											</div>
										</b-card-text>
									</b-card-body>
								</b-collapse>
							</b-card>
							<!---------------------------------------->

							<!-- Chat Pipeline -->
							<b-card no-body class="settings-collapse hard-border" v-if="features.chat_pipeline.status === true">
								<a v-b-toggle.chat_pipeline class="text-dark" href="javascript: void(0);">
									<b-card-header header-tag="header" role="tab">
										<h3 class="m-0 d-inline-flex">
											<!-- {{ $t('server.view.configuration.timezone.title') }} -->
											Chat Pipeline
											<span class="ml-2 badge badge-primary text-black">
                        <i class="fad fa-flask"/> Beta
                      </span>
										</h3>
										<div class="d-inline-flex float-right">
											<div class="btn btn-sm btn-dark">
												{{ $t('server.view.configuration.expand') }}
											</div>
										</div>
									</b-card-header>
								</a>
								<b-collapse id="chat_pipeline" accordion="configuration" role="tabpanel">
									<b-card-body>
										<b-card-text>
											<!-- Module enabled -->
											<div class="row">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															Module Enabled
															<span class="badge badge-warning text-black">
                                <i class="fad fa-badge-dollar"/> Billing Control
                              </span>
															<!-- {{ $t('server.view.configuration.banning.protectwl.title') }} -->
														</h4>
														<span>
                              Global toggle for all Chat Pipeline systems. Features that incur additional costs can be globally disabled or will be automatically disabled based on your configured billing limits.
															<!-- {{ $t('server.view.configuration.banning.protectwl.description') }} -->
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.chat_pipeline.status"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														/>
													</div>
												</div>
											</div>

											<!-- Advanced_screening -->
											<div class="row mt-2" v-if="features.advanced_screening.status === true">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															<!-- {{ $t('server.view.configuration.banning.protectwl.title') }} -->
															Automated Chat Screening
															<span class="badge badge-light">
                                <i class="fad fa-radar"/> Radar-Suite
                              </span>
														</h4>
														<span>
                              <!-- {{ $t('server.view.configuration.banning.protectwl.description') }} -->
                              Machine learning based filter to detect toxicity, insults, threats and issues automated kicks
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.chat_pipeline.advanced_screening"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														/>
													</div>
												</div>
											</div>
										</b-card-text>
									</b-card-body>
								</b-collapse>
							</b-card>
							<!---------------------------------------->

							<!-- Queue Priority -->
							<b-card no-body class="settings-collapse hard-border" v-if="features.queue_priority_sync.status === true">
								<a v-b-toggle.queue_priority class="text-dark" href="javascript: void(0);">
									<b-card-header header-tag="header" role="tab">
										<h3 class="m-0 d-inline-flex">
											{{ $t('server.view.configuration.queue_priority.title') }}
											<template v-if="configuration.queue_priority.meta.error">
                        <span class="ml-2 badge badge-danger text-black">
                          <i class="fad fa-times-hexagon"/> Error
                        </span>
											</template>
											<template v-else>
												<template v-if="configuration.queue_priority.meta.last_sync">
                          <span class="ml-2 badge badge-success text-black">
                            <i class="fad fa-sync-alt"/> Last sync {{
		                          $d(parseDate(configuration.queue_priority.meta.last_sync), 'datetime', getDTLocale())
	                          }}
                          </span>
												</template>
												<template v-else>
                          <span class="ml-2 badge badge-warning text-black">
                            <i class="fad fa-sync-alt"/> {{ $t('server.view.configuration.queue_priority.module.sync_pending') }}
                          </span>
												</template>
											</template>

										</h3>
										<div class="d-inline-flex float-right">
											<div class="btn btn-sm btn-dark">
												{{ $t('server.view.configuration.expand') }}
											</div>
										</div>
									</b-card-header>
								</a>
								<b-collapse id="queue_priority" accordion="configuration" role="tabpanel">
									<b-card-body>
										<b-card-text>
											<template v-if="configuration.queue_priority.meta.error">
												<div class="row mb-2">
													<div class="col">
														<div class="alert alert-warning">
															<h5 class="alert-heading">
																{{ $t('server.view.configuration.queue_priority.errors.title') }}
															</h5>
															<hr>
															<p v-if="configuration.queue_priority.meta.details === 'generic'">
																{{ $t('server.view.configuration.queue_priority.errors.generic') }}

															</p>
															<p v-else-if="configuration.queue_priority.meta.details === 'bucket_setup_required'">
																{{ $t('server.view.configuration.queue_priority.errors.bucket_setup_required') }}

															</p>
															<p v-else-if="configuration.queue_priority.meta.details === 'connection_denied'">
																{{ $t('server.view.configuration.queue_priority.errors.connection_denied') }}

															</p>
															<p v-else-if="configuration.queue_priority.meta.details === 'credentials_invalid'">
																{{ $t('server.view.configuration.queue_priority.errors.credentials_invalid') }}

															</p>
															<p v-else-if="configuration.queue_priority.meta.details === 'no_root_found'">
																{{ $t('server.view.configuration.queue_priority.errors.no_root_found') }}
															</p>
														</div>
													</div>
												</div>
											</template>
											<template v-else>
												<div class="row mb-2">
													<div class="col">
														<div class="alert alert-warning">
															<h5 class="alert-heading text-black">
																{{ $t('server.view.configuration.queue_priority.info.title') }}

															</h5>
															<hr>
															<p class="text-black">
																{{ $t('server.view.configuration.queue_priority.info.description') }}

															</p>
														</div>
													</div>
												</div>
											</template>

											<!-- Module enabled -->
											<div class="row">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>

														</h4>
														<span>
                              {{ $t('server.view.configuration.queue_priority.module.text') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.queue_priority.status"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														/>
													</div>
												</div>
											</div>

											<div class="row mt-4">
												<div class="col-lg-4 col-sm-12">
													<h5>{{ $t('server.view.configuration.queue_priority.fields.hostname') }}</h5>
													<hr>
													<b-input v-model="configuration.queue_priority.hostname" :value="gameserver.host" size="lg"
													         type="text" class="form-control"/>
												</div>
												<div class="col-lg-4 col-sm-12">
													<h5>{{ $t('server.view.configuration.queue_priority.fields.port') }}</h5>
													<hr>
													<b-input v-model="configuration.queue_priority.port" size="lg" type="number"
													         class="form-control"/>
												</div>
												<!--
												<div class="col-lg-4 col-sm-12">
													<h5>SSL <small class="text-muted">Enable if FTP over TLS/SSl is required</small></h5>
													<hr>
													<div class="text-center">
														<toggle-button v-model="configuration.queue_priority.ssl"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mb-1"
														/>
													</div>
												</div>
												-->
											</div>
											<div class="row mt-3">
												<div class="col-lg-6 col-sm-12">
													<h5>{{ $t('server.view.configuration.queue_priority.fields.username') }}</h5>
													<hr>
													<b-input v-model="configuration.queue_priority.username" size="lg" type="text"
													         class="form-control"/>
												</div>
												<div class="col-lg-6 col-sm-12">
													<h5>{{ $t('server.view.configuration.queue_priority.fields.password') }}</h5>
													<hr>
													<b-input v-model="configuration.queue_priority.password" size="lg" type="text"
													         class="form-control"/>
												</div>
											</div>
											<div class="row mt-3">
												<div class="col col-sm-12">
													<h5>{{ $t('server.view.configuration.queue_priority.fields.root.title') }} <small class="text-muted">{{ $t('server.view.configuration.queue_priority.fields.root.description') }}</small></h5>
													<hr>
													<b-input v-model="configuration.queue_priority.root" size="lg" type="text"
													         class="form-control"/>
												</div>
											</div>
										</b-card-text>
									</b-card-body>
								</b-collapse>
							</b-card>
							<!---------------------------------------->
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-sm-12">
					<div class="" ref="floatingContainer">
						<button class="btn btn-block hard-border save-btn"
						        v-on:click="saveConfiguration()"
						        :disabled="inProgress"
						        :class="{
	                    'disabled': inProgress,
	                    'btn-success': configurationChanged === true,
	                    'btn-primary': configurationChanged === false
	                  }"
						>
							<half-circle-spinner
									v-if="inProgress"
									:animation-duration="1200"
									:size="16"
									class="align-middle d-inline-block"
							/>
							<template v-if="!inProgress">
								{{ $t('server.view.configuration.save.button') }}
							</template>
						</button>
						<div class="text-center small text-muted" v-if="configurationChanged === true">
							{{ $t('server.view.configuration.save.info') }}
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else-if="error">
			<div class="row">
				<div class="col-lg-12 col-sm-12">
					<div class="card border border-danger">
						<div class="card-header bg-transparent border-danger">
							<h5 class="my-0 text-danger">
								<i class="far fa-exclamation-circle mr-3"></i>
								{{ $t('error.server.generic.title') }}
							</h5>
						</div>
						<div class="card-body pt-0">
							<h5 class="card-title mt-0"> {{ $t('error.server.generic.component') }}</h5>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="row">
				<div class="col-lg-11">
					<!-- Content row one -->
					<div class="row">
						<div class="col">
							<div class="card">
								<div class="card-body">
									<div class="row">
										<div class="col-lg-4">
											<VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
										</div>
										<div class="col-lg-4">
											<VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
										</div>
										<div class="col-lg-4">
											<VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<div class="card">
								<div class="card-body">
									<div class="row">
										<div class="col-lg-4">
											<VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
										</div>
										<div class="col-lg-4">
											<VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
										</div>
										<div class="col-lg-4">
											<VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<div class="card">
								<div class="card-body">
									<div class="row">
										<div class="col-lg-4">
											<VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
										</div>
										<div class="col-lg-4">
											<VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
										</div>
										<div class="col-lg-4">
											<VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>
